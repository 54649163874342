/* Global styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

/* Navigation */
.nav {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #007BFF;
  color: white;
}

.nav ul {
  list-style: none;
  display: flex;
}

.nav li {
  margin: 0 15px;
}

.nav a {
  color: white;
  text-decoration: none;
}

.logo {
  font-size: 1.5rem;
}

/* Hero Section */
.hero {
  text-align: center;
  padding: 50px;
  background-color: #007BFF;
  color: white;
}

.hero h2 {
  font-size: 2.5rem;
}

.hero p {
  margin: 20px 0;
  font-size: 1.2rem;
}

.cta-button {
  padding: 15px 30px;
  font-size: 1rem;
  background-color: #28a745;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #218838;
}

/* Footer */
.footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 20px 0;
}
